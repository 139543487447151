import React from 'react';
import '../../shared/imports/_variables.css';
import './profile.css';
import PWAHeader from '../../Components/pwa-header/pwa-header';
import PWANav from '../../Components/pwa-nav/pwa-nav';
import Container from "../../Components/container/container";
import Location from "../../ImportedComponents/Location/location";
import {ReactComponent as LogoutSymbol} from "../../assets/symbols/logout.svg"
import {ReactComponent as ProfileSymbol} from "../../assets/symbols/profile.svg"
import {ReactComponent as NotificationSymbol} from "../../assets/symbols/notification.svg"
import {ReactComponent as PrivacySymbol} from "../../assets/symbols/privacy.svg"
import {ReactComponent as DocumentSymbol} from "../../assets/symbols/document.svg"
import {ReactComponent as ShareSymbol} from "../../assets/symbols/share.svg"
import {ReactComponent as SettingsSymbol} from "../../assets/symbols/settings.svg"


function Profile() {

  return (
    <div className='pwa-page'>
      <PWAHeader/>
      <Container className="profile">
        <div className="profile__head">
          <h1 className="profile__name">
            Jamie
          </h1>
          <div className="profile__location">
            <Location variant={undefined}>Tsuen Wan, Hong Kong</Location>
          </div>
        </div>
        <div className="profile__body">
          <nav className="profile__navigation">
            <a className="profile__link" href="#">
              <ProfileSymbol className="profile__symbol"/>
              Personal Information
            </a>
            <a className="profile__link" href="#">
              <NotificationSymbol className="profile__symbol"/>
              Notifications
            </a>
            <a className="profile__link" href="#">
              <PrivacySymbol className="profile__symbol"/>
              Privacy Policy
            </a>
            <a className="profile__link" href="#">
              <DocumentSymbol className="profile__symbol"/>
              Terms and Conditions
            </a>
            <a className="profile__link" href="#">
              <ShareSymbol className="profile__symbol"/>
              Share
            </a>
            <a className="profile__link" href="#">
              <SettingsSymbol className="profile__symbol"/>
              Setting
            </a>
          </nav>
        </div>
        <div className="profile__footer">
          <a className="profile__policy" href="#">
            Privacy Policy
          </a>
          <div className="profile__version">
            <div className="profile__version-heading">Version</div>
            <div className="profile__version-number">0.0.0</div>
          </div>
          <div className="profile__footer-actions">
            <a className="profile__logout" href="#">
              <LogoutSymbol className="profile__logout-symbol"/>
              Log Out
            </a>
          </div>
        </div>
      </Container>
      <PWANav/>
    </div>
  )
}

export default Profile;