import backendImageApi from "./backendImageApi";

export const GetImage = async (uuid: string): Promise<string> => {
  const response = await backendImageApi.get(`v1/s3/download?key=${uuid}.jpg`, {
    responseType: "blob",
  });
  const blob = new Blob([response.data], { type: "image/png" });
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

// import { AxiosResponse } from "axios";
// import backendImageApi from "./backendImageApi";

// type GetImageResponse = { };
// //create a getMemberships function that takes in a payload of type GetMembershipsPayload and returns a promise of type GetMembershipsResponse and it is a get request
// export const GetImage = async (uuid: string): Promise<GetImageResponse> => {
//   const response = await backendImageApi.get<
//   any,
//     AxiosResponse<GetImageResponse, 
//     {}>
//   >(`v1/s3/download?key=${uuid}.jpg`);
//   console.log(response.data);
//   return response;
// };
