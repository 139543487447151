import React from "react";
import "./location.css";
import { ReactComponent as GeoIcon } from "./../../assets/symbols/geo.svg";

function Location({ variant, children }) {
  return (
    <div className={`location${variant ? " location--" + variant : ""}`}>
      <GeoIcon className="location__icon" />
      {children}
    </div>
  );
}

export default Location;
