import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type UpdateNamePayload = {
  shopperUuid: string;
  name: string;
};

type UpdateNameResponse = {};

export const updateName = async (
  payload: UpdateNamePayload
): Promise<UpdateNameResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<UpdateNameResponse>,
    UpdateNamePayload
  >("v1/shopper/update/profile", payload);
  return response.data;
};
