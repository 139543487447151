import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authCompletedImagePath from "./content/auth-completed@3x.jpg";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { useLocation, useNavigate } from "react-router-dom";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import openNotification from "../../Components/Antd/Notification/Notification";
import { updateName } from "../../services/api/updateName";
import useLoggedInUser from "../../hooks/useLoggedInUser";

function Name() {
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();
  const [displayName, setDisplayName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();

  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );

  const handleClick = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!loggedInUser) {
        throw new Error("User not logged in");
      }

      if (!displayName) {
        openNotification("warning", "Please enter your Display Name.", null);
        return;
      }

      setIsLoading(true);

      try {
        const response = await updateName({
          shopperUuid: loggedInUser.uuid,
          name: displayName,
        });

        console.log(response);
        openNotification("success", `Your name updated successfully`, null);
        navigate(redirect || "/home");
      } catch (err) {
        console.error(err);
        openNotification("error", `Error while setting your name`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [displayName, navigate, redirect, loggedInUser?.uuid],
  );

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/verify");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <fieldset disabled={isLoading}>
          <Container className="auth__wrap" variant="smartphone-column">
            <img
              className="auth__illustration"
              src={authCompletedImagePath}
              width="260"
              height="243"
              alt=""
            />
            <div className="auth__heading">What’s your name?</div>
            <div className="auth__panel">
              <div className="auth__field">
                <Label className="label">Display name</Label>
                <AppInput
                  tag="input"
                  type="text"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="auth__submit">
              <AppButton
                uniqueModifier="submit"
                fullWidth
                onClick={handleClick}
              >
                Next Step
              </AppButton>
            </div>
          </Container>
        </fieldset>
      </div>
    </div>
  );
}

export default Name;
