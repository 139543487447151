import React, { useCallback } from "react";
import "./pwa-header.css";

import { ReactComponent as ScannerIcon } from "../../assets/symbols/scanner.svg";
import ProfileImage from "../../assets/temp/profile@3x.jpg";
import CoffeePlaceLogoImage from "../../assets/temp/coffee-place-logo@3x.png";
import { NavLink } from "react-router-dom";
import { AppRoute } from "../../shared/const";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { authSlice } from "../../store/slices/auth";
import { logout } from "../../services/api/logout";

function PWAHeader() {
  const dispatch = useDispatch();
  const onLogout = useCallback(async () => {
    await logout();
    dispatch(authSlice.actions.logout());
  }, [dispatch]);

  return (
    <header className="pwa-header">
      <NavLink to={AppRoute.Home} className="pwa-header__logo">
        <img className="pwa-header__emblem" src={CoffeePlaceLogoImage} alt="" />
      </NavLink>
      <div className="pwa-header__brand-name">Coffee Place</div>
      <NavLink to={AppRoute.QR} className="pwa-header__scan-handler">
        <ScannerIcon className="pwa-header__scanner" />
      </NavLink>
      <Dropdown
        placement="bottomLeft"
        overlayStyle={{ minWidth: 150 }}
        menu={{
          items: [
            {
              key: "profile",
              label: <NavLink to={AppRoute.Profile}>Profile</NavLink>,
            },
            { type: "divider" },
            {
              key: "logout",
              label: (
                <NavLink to="#" onClick={onLogout}>
                  Logout
                </NavLink>
              ),
            },
          ],
        }}
        trigger={["click"]}
      >
        <NavLink to="#" className="pwa-header__profile">
          <img className="pwa-header__user-pic" src={ProfileImage} alt="" />
        </NavLink>
      </Dropdown>
    </header>
  );
}

export default PWAHeader;
