import React, { useCallback, useEffect, useState } from "react";
import "../../shared/imports/_variables.css";
import "./membership.css";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import MembershipCard from "../../Components/membership-card/membership-card";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import { useParams } from "react-router-dom";
import {
  GetMembershipsResponse,
  GetMemberships,
} from "../../services/api/getMemberships";
import { joinMembership } from "../../services/api/joinMembership";
import openNotification from "../../Components/Antd/Notification/Notification";

const getMembership = async (loggedInUserUuid: string, programUuid: string) => {
  const memberships = await GetMemberships(loggedInUserUuid);

  return memberships.find((x) => x.uuid === programUuid);
};

const MembershipPage: React.FC = () => {
  const loggedInUser = useLoggedInUser();
  const { uuid } = useParams<{ uuid: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [membership, setMembership] = useState<GetMembershipsResponse[0]>();

  useEffect(() => {
    if (!uuid || !loggedInUser?.uuid) {
      return;
    }

    setIsLoading(true);
    getMembership(loggedInUser.uuid, uuid)
      .then(async (membership) => {
        if (membership) {
          setMembership(membership);
          setIsLoading(false);
        }

        try {
          await joinMembership({
            membershipUuid: uuid,
            shopperUuid: loggedInUser.uuid,
          });
          openNotification(
            "success",
            `Successfully joined membership for the program`,
            null,
          );
          setIsLoading(false);

          setMembership(await getMembership(loggedInUser.uuid, uuid));
        } catch (e) {
          openNotification(
            "error",
            `Failed to join membership for the program`,
            null,
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        openNotification("error", `Failed to load memberships`, null);
      });
  }, [loggedInUser?.uuid, uuid]);

  return (
    <div className="pwa-page">
      <PWAHeader />
      <Container className="membership">
        {isLoading ? <h3>Loading...</h3> : <MembershipCard />}
      </Container>
      <PWANav />
    </div>
  );
};

export default MembershipPage;
