/* This component is some sort of wrapper for imported Deal component.
 * It downloads images and calculates dates and then pass it the Deal.
 * Ideally this component should be deleted, and it's functionality
 * (images and dates) should be moved into the Deal.
 */

import React, { useState, useEffect } from "react";
import "../../shared/imports/hardcoded-components/deal.css";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import { GetImage } from "../../services/api/getImage";
import Deal from "../../ImportedComponents/Deal/deal";

type Props = {
  brandName: string;
  startDate: string;
  expriyDate: string;
  discount: string;
  product: string;
  location: string;
  backgroundColor: string;
  voucherImage: string;
  brandImage: string;
};

function calculateDateDifference(expiryDate: string | number | Date) {
  // Create date objects for today and the expiry date
  const today = new Date().getTime();
  const expiry = new Date(expiryDate).getTime();

  // Calculate the difference in milliseconds
  const diffInMs = expiry - today;

  // Convert the difference from milliseconds to days
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
}

const VoucherCard: React.FC<Props> = ({
  brandName,
  startDate,
  expriyDate,
  discount,
  product,
  location,
  backgroundColor,
  voucherImage,
  brandImage,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [logoSrc, setLogoSrc] = useState<string | null>(null);
  useEffect(() => {
    const downloadImage = async (
      url: string,
      setImageSrc: {
        (value: React.SetStateAction<string | null>): void;
        (value: React.SetStateAction<string | null>): void;
        (arg0: string): void;
      },
    ) => {
      try {
        const downloadedImage = await GetImage(url);
        setImageSrc(downloadedImage);
      } catch (error) {
        console.error("Failed to download image", error);
      }
    };

    downloadImage(voucherImage, setImageSrc);
    downloadImage(brandImage, setLogoSrc);
  }, [voucherImage]);
  console.log(voucherImage);
  expriyDate = calculateDateDifference(expriyDate).toString();
  voucherImage = voucherImage || "";
  return (
    <Deal
      fullView={false}
      hasProgressCard={false}
      background={backgroundColor}
      brandLogo={brandImage ? logoSrc || "" : BrandLogoPlaceholder}
      campaignPicture={voucherImage ? imageSrc || "" : BrandLogoPlaceholder}
      brandName={brandName}
      offerTitle={product}
      offerValue={discount}
      location={location}
      duration={expriyDate}
      requirements={[""]}
      description={""}
      termsNConditions={""}
      startDate={new Date(startDate)}
      endDate={new Date()}
    />
  );
};

export default VoucherCard;
