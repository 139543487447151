import React from "react";
import "./tabs-alike-nav.css";

function TabsAlikeNav() {
  return (
    <div className="tabs-alike-nav hide-scrollbar">
      <div className="tabs-alike-nav__ribbon">
        <a className="tabs-alike-nav__link tabs-alike-nav__link--current" href="#">
          <div className="tabs-alike-nav__inner" style={{backgroundColor: '#935523'}}>
            All
          </div>
        </a>
        <a className="tabs-alike-nav__link" href="#">
          <div className="tabs-alike-nav__inner" style={{backgroundColor: '#935523'}}>
            New
          </div>
        </a>
        <a className="tabs-alike-nav__link" href="#">
          <div className="tabs-alike-nav__inner" style={{backgroundColor: '#935523'}}>
            Expired Soon
          </div>
        </a>
      </div>
    </div>
  );
}

export default TabsAlikeNav;
