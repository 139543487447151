import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import { ReactComponent as ChevronLeftSymbol } from "../../assets/symbols/chevron-left.svg";
import authOtpRegisterImagePath from "./content/auth-otp-register@3x.jpg";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import Container from "../../Components/container/container";
import SplitInput from "../../ImportedComponents/SplitInput/split_input";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import openNotification from "../../Components/Antd/Notification/Notification";
import { Emaillogin, Phonelogin } from "../../services/api/login";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import { getEmailOtp, getPhoneOtp } from "../../services/api/getOtp";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { authSlice } from "../../store/slices/auth";
import axios from "axios";
import backendApi from "../../services/api/backendApi";

function Verification() {
  const phoneNumber = useSelector((state: RootState) => state.auth.phoneNumber);
  const email = useSelector((state: RootState) => state.auth.emailForLogin);

  const isLoginTypeNumber = useSelector(
    (state: RootState) => state.auth.isLoginTypeNumber,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ver_code, setver_code] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const turnstileRef = useRef<TurnstileInstance>(null);
  const [requireTurnstile, setRequireTurnstile] = useState(false);
  const { search } = useLocation();

  const redirect = useMemo(
    () => new URLSearchParams(search).get("redirect") || undefined,
    [search],
  );

  useEffect(() => {
    // If email is not set, redirect back to login page

    if (isLoginTypeNumber && !phoneNumber) {
      navigate("/");
    }

    if (!isLoginTypeNumber && !email) {
      navigate("/");
    }
  }, [navigate, phoneNumber, email]);

  const handlePhoneLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();

      if (!phoneNumber) {
        throw new Error("Email not set");
      }
      if (!ver_code) {
        openNotification("warning", "Please enter a OTP.", null);
        return;
      }

      setIsLoading(true);

      try {
        const response = await Phonelogin({
          phoneNumber: phoneNumber,
          otpCode: ver_code,
          deviceID: getDeviceUuid(),
        });

        // const response = await backendApi.post(`${process.env.REACT_APP_API_URL}v3/auth/shopper/pwa/login`, {
        //   phoneNumber: "+919852339220",
        //   otpCode: ver_code,
        //   deviceID: getDeviceUuid()
        // });

        console.log(response);

        dispatch(
          authSlice.actions.login({
            phoneNumber: response.shopper.email,
            id: response.shopper.id,
            name: response.shopper.name,
            uuid: response.shopper.uuid,
            email: "",
          }),
        );

        openNotification("success", `User verified successfully`, null);

        if (response.shopper.name) {
          navigate(redirect || "/home");
        } else {
          // If name is not set, redirect to name page to ask user to enter their display name
          navigate({
            pathname: "/name",
            search,
          });
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Invalid OTP`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [ver_code, navigate, redirect, search, phoneNumber, dispatch],
  );

  const handleEmailLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();

      if (!email) {
        throw new Error("Email not set");
      }
      if (!ver_code) {
        openNotification("warning", "Please enter a OTP.", null);
        return;
      }

      setIsLoading(true);

      try {
        const response = await Emaillogin({
          email: email,
          otpCode: ver_code,
          deviceID: getDeviceUuid(),
        });

        // const response = await backendApi.post(`${process.env.REACT_APP_API_URL}v3/auth/shopper/pwa/login`, {
        //   phoneNumber: "+919852339220",
        //   otpCode: ver_code,
        //   deviceID: getDeviceUuid()
        // });

        console.log(response);

        dispatch(
          authSlice.actions.login({
            phoneNumber: "",
            id: response.shopper.id,
            name: response.shopper.name,
            uuid: response.shopper.uuid,
            email: response.shopper.email,
          }),
        );

        openNotification("success", `User verified successfully`, null);

        if (response.shopper.name) {
          navigate(redirect || "/home");
        } else {
          // If name is not set, redirect to name page to ask user to enter their display name
          navigate({
            pathname: "/name",
            search,
          });
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Invalid OTP`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [ver_code, navigate, redirect, search, phoneNumber, dispatch],
  );

  const handleSendOTP = useCallback(
    async (turnstileToken: string) => {
      if (isLoginTypeNumber && !phoneNumber) {
        throw new Error("Phone Number not set");
      }
      if (!isLoginTypeNumber && !email) {
        throw new Error("Email not set");
      }

      if (!turnstileToken) {
        openNotification(
          "warning",
          "Please complete the captcha challenge.",
          null,
        );
        return;
      }

      setIsLoading(true);
      try {
        if (isLoginTypeNumber && phoneNumber) {
          await getPhoneOtp({
            phoneNumber,
            deviceID: getDeviceUuid(),
            turnstileToken,
          });
          openNotification(
            "success",
            `An OTP has been delivered to the phone number ${phoneNumber}`,
            null,
          );
        } else if (email) {
          await getEmailOtp({
            email,
            deviceID: getDeviceUuid(),
            turnstileToken,
          });
          openNotification(
            "success",
            `An OTP has been delivered to the email address ${email}`,
            null,
          );
        } else {
          throw new Error("Phone number or email is undefined.");
        }
        setRequireTurnstile(false);
      } catch (err) {
        console.error(err);
        openNotification("error", `Unable to send OTP`, null);
      } finally {
        setIsLoading(false);
      }
      setCanResendOTP(false);
      setTimer(60);
    },
    [phoneNumber, navigate, turnstileRef.current],
  );

  const [timer, setTimer] = useState(60);
  const [canResendOTP, setCanResendOTP] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer == 0) {
      setCanResendOTP(true);
    }
  }, [timer]);

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <a className="auth__back" href="#">
              <ChevronLeftSymbol
                className="auth__chevron"
                onClick={() => {
                  navigate("/");
                }}
              />
              <span className="visuallyhidden">Назад</span>
            </a>
          </Container>
        </div>
        <form
          onSubmit={isLoginTypeNumber ? handlePhoneLogin : handleEmailLogin}
        >
          <fieldset disabled={isLoading}>
            <Container className="auth__wrap" variant="smartphone-column">
              <img
                className="auth__illustration"
                src={authOtpRegisterImagePath}
                width="260"
                height="243"
                alt=""
              />
              <div className="auth__heading">Enter the Verification Code</div>
              <div className="auth__note">
                Enter the 4-digit number that we sent to {phoneNumber}
              </div>
              <div className="auth__panel">
                <SplitInput>
                  <OtpInput
                    value={ver_code}
                    onChange={setver_code}
                    numInputs={4}
                    inputType={"tel"}
                    shouldAutoFocus={true}
                    skipDefaultStyles={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="input"
                  />
                </SplitInput>
                <div className="auth__code-hint">
                  The code is valid for an hour.
                </div>
              </div>
              <div className="auth__submit">
                <AppButton uniqueModifier="submit" fullWidth type="submit">
                  Log in
                </AppButton>
              </div>
              <div className="auth__alternation">
                Didn’t Receive Anything?{" "}
                <button
                  className="auth__alt-action"
                  type="button"
                  disabled={!canResendOTP}
                  style={{
                    color: `${!canResendOTP ? "gray" : ""}`,
                    cursor: `${!canResendOTP ? "not-allowed" : "pointer"}`,
                  }}
                  onClick={() => {
                    if (requireTurnstile) {
                      turnstileRef.current?.reset();
                    } else {
                      setRequireTurnstile(true);
                    }
                  }}
                >
                  Resend&nbsp;Code&nbsp;
                </button>
                {!canResendOTP && `${timer}s`}
                {requireTurnstile && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Turnstile
                      siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY ?? ""}
                      options={{
                        appearance: "interaction-only",
                      }}
                      onError={() => console.log("captcha validation failed")}
                      onSuccess={handleSendOTP}
                      ref={turnstileRef}
                    />
                  </div>
                )}
              </div>
            </Container>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default Verification;
