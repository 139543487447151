import { AxiosResponse } from "axios";
import backendApi from "./backendApi";
import { Membership } from "../../types/membership";

export type GetMembershipsResponse = Membership[];

//create a getMemberships function that takes in a payload of type GetMembershipsPayload and returns a promise of type GetMembershipsResponse and it is a get request
export const GetMemberships = async (
  uuid: string,
): Promise<GetMembershipsResponse> => {
  const response = await backendApi.get<
    any,
    AxiosResponse<GetMembershipsResponse, {}>
  >(`v1/membership/${uuid}`);
  console.log(response.data);
  return response.data;
};
