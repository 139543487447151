import React from "react";
import "./media.css";

const Media: React.FC<
  React.PropsWithChildren<{
    url: string;
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string }
    >;
  }>
> = ({ children, url, icon: IconComponent }) => {
  return (
    <a className="media" href={url}>
      <IconComponent className="media__icon" />
      {children}
    </a>
  );
};

export default Media;
