import React, { useState, useEffect, useCallback } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import TabsAlikeNav from "../../Components/tabs-alike-nav/tabs-alike-nav";
import MembershipCard from "../../Components/membership-card/membership-card";
import VoucherCard from "../../Components/voucher-card/voucher-card";

import "../../shared/imports/_variables.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/deal.css";
import "../../Components/pwa-header/pwa-header.css";
import "./home.css";
import { AppRoute } from "../../shared/const";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import openNotification from "../../Components/Antd/Notification/Notification";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import { GetVoucher, GetVoucherResponse } from "../../services/api/getVouchers";
import CoffeePlaceLogo from "../../assets/temp/coffee-place-logo@3x.png";
import CoffeePlacePhoto from "../../assets/temp/deal-photo-coffee-place@3x.jpg";
import Deal from "../../ImportedComponents/Deal/deal";

function Home() {
  const loggedInUser = getLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [vouchers, setVouchers] = useState<GetVoucherResponse>();
  const [voucherCount, setVoucherCount] = useState(0);
  // call handleGetMemberships when the page loads

  const handleGetVoucher = useCallback(async () => {
    setIsLoading(true);

    try {
      console.log(loggedInUser?.uuid ?? "");
      const response = await GetVoucher(loggedInUser?.uuid ?? "");
      console.log(response);
      setVouchers(response); // Update the type of 'memberships' state
      setVoucherCount(response.length);
      openNotification("success", `Get vouchers success`, null);
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load vouchers`, null);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetVoucher();
  }, [handleGetVoucher]);
  return (
    <>
      <div className="pwa-page">
        <PWAHeader />
        <Container className="home">
          <div className="home__card">
            <MembershipCard voucherCount={voucherCount} />
          </div>
          <div className="home__filters">
            <TabsAlikeNav />
          </div>
          <div className="home__list">
            {vouchers?.length ? (
              vouchers.map((voucher) => (
                <NavLink to={AppRoute.HomeItem} className="home__item">
                  <VoucherCard
                    key={voucher.id}
                    brandName={voucher.Program.name}
                    startDate={voucher.startDate}
                    expriyDate={voucher.expiryDate}
                    discount={voucher.valueDescription}
                    product={voucher.campaignName}
                    location={voucher.Program.physicalAddress}
                    backgroundColor={voucher.Program.brandColor}
                    voucherImage={voucher.imageId}
                    brandImage={voucher.Program.imageURL}
                  />
                </NavLink>
              ))
            ) : (
              <div className="home__null">
                <p> Go Get Some Vouchers</p>
              </div>
            )}
          </div>
        </Container>
        <PWANav />
      </div>
    </>
  );
}

export default Home;
