import React from 'react';
import '../../shared/imports/_variables.css';
import './about.css';
import PWAHeader from '../../Components/pwa-header/pwa-header';
import PWANav from '../../Components/pwa-nav/pwa-nav'
import Container from "../../Components/container/container";
import Card from "../../Components/card/card";
import Wysiwyg from "../../Components/wysiwyg/wysiwyg";
import Media from "../../Components/media/media";
import {ReactComponent as MediaWebSymbol} from "../../assets/symbols/media-web.svg"
import {ReactComponent as MediaInstagramSymbol} from "../../assets/symbols/media-instagram.svg"
import {ReactComponent as MediaFacebookSymbol} from "../../assets/symbols/media-facebook.svg"


function About() {

  return (
    <div className='pwa-page'>
      <PWAHeader/>
      <Container className="about">
        <h1 className="about__heading">
          About
        </h1>
        <div className="about__body">
          <Card className="about__section">
            <Wysiwyg className="about__description">
              <p>Welcome to "Coffee Place," where the aroma of freshly ground coffee beans fills the air, and the sound of steaming milk and friendly chatter creates a cozy atmosphere.</p>
              <p>Our coffee shop is a place where you can unwind, catch up with friends, or simply enjoy a moment of tranquility</p>
            </Wysiwyg>
          </Card>
          <Card className="about__section">
            <div className="about__social-media">
              <Media url="http://instagram.com" icon={MediaWebSymbol}/>
              <Media url="http://instagram.com" icon={MediaInstagramSymbol}/>
              <Media url="http://instagram.com" icon={MediaFacebookSymbol}/>
            </div>
          </Card>
        </div>
      </Container>
      <PWANav/>
    </div>
  )
}

export default About;