import React from "react";
import "./home-item.css";
import { AppRoute } from "../../shared/const";
import Deal from "../../ImportedComponents/Deal/deal";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import Container from "../../Components/container/container";
import Back from "../../Components/back/back";
import CoffeePlaceLogo from "../../assets/temp/coffee-place-logo@3x.png";
import CoffeePlacePhoto from "../../assets/temp/deal-photo-coffee-place@3x.jpg";

function HomeItem() {
  return (
    <>
      <div className="pwa-page">
        <PWAHeader />
        <Container className="home-item">
          <div className="home-item__back">
            <Back to={AppRoute.Home}>Offers detail</Back>
          </div>
          <div className="home-item__body">
            <Deal
              fullView
              hasProgressCard
              background={"#CBAA84"}
              brandLogo={CoffeePlaceLogo}
              campaignPicture={CoffeePlacePhoto}
              brandName={"Candle Shop"}
              offerTitle={"Offer title text"}
              offerValue={"Offer value"}
              location={"Program location"}
              duration={"Duration text"}
              requirements={["Requirements text"]}
              description={"Description text"}
              termsNConditions={"Condition text"}
              startDate={new Date()}
              endDate={new Date()}
            />
          </div>
        </Container>
        <PWANav />
      </div>
    </>
  );
}

export default HomeItem;
