import React from 'react';
import '../../shared/imports/_variables.css';
import './venue.css';
import PWAHeader from '../../Components/pwa-header/pwa-header';
import PWANav from '../../Components/pwa-nav/pwa-nav';
import Container from "../../Components/container/container";
import Brief from "../../Components/brief/brief";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";


function Venue() {

  // const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  });

  const center = {
    lat: 7.2905715, // default latitude
    lng: 80.6337262, // default longitude
  };

  const mapContainerStyle = {
    width: '100%',
    height: '220px',
  };

  return (
    <Container className="pwa-page">
      <PWAHeader />
      <div className='venue container'>
        <h1 className="venue__heading">
          Location
        </h1>
        <div className="venue__map">

          {/*Temp*/}
          <div style={{ height: '220px', background: 'purple', color: 'white', display: "flex", alignItems: "center", justifyContent: "center" }} >
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
              />
            )}
          </div>
          {/*/Temp*/}

        </div>
        <div className="venue__list">
          <Brief />
          <Brief />
          <Brief />
        </div>
      </div>
      <PWANav />
    </Container>
  )
}

export default Venue;