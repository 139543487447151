import React from "react";
import "./pwa-nav.css";
import {NavLink} from "react-router-dom";
import {ReactComponent as HomeSymbol} from "../../assets/symbols/home.svg";
import {ReactComponent as LocationSymbol} from "../../assets/symbols/location.svg";
import {ReactComponent as AboutSymbol} from "../../assets/symbols/about.svg";
import MeedNavIcon from "../../assets/icons/meed-nav@3x.png";
import {AppRoute} from "../../shared/const";


function PWANav() {
  return (
    <nav className="pwa-nav">
      <NavLink to={AppRoute.Home} className={({isActive}) => 'pwa-nav__link' + ( isActive ? ' pwa-nav__link--current' : '')}>
        <HomeSymbol className="pwa-nav__symbol"/>
        <div className="pwa-nav__label">Home</div>
      </NavLink>
      <NavLink to={AppRoute.Location} className={({isActive}) => 'pwa-nav__link' + ( isActive ? ' pwa-nav__link--current' : '')}>
        <LocationSymbol className="pwa-nav__symbol"/>
        <div className="pwa-nav__label">Location</div>
      </NavLink>
      <NavLink to={AppRoute.About} className={({isActive}) => 'pwa-nav__link' + ( isActive ? ' pwa-nav__link--current' : '')}>
        <AboutSymbol className="pwa-nav__symbol"/>
        <div className="pwa-nav__label">About</div>
      </NavLink>
      <NavLink to={AppRoute.Meed} className={({isActive}) => 'pwa-nav__link' + (isActive ? ' pwa-nav__link--current' : '')}>
        <img className="pwa-nav__icon" src={MeedNavIcon} alt=""/>
        <div className="pwa-nav__label">meed</div>
      </NavLink>
    </nav>
  );
}

export default PWANav;
