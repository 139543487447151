import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

type JoinMembershipPayload = {
  shopperUuid: string;
  membershipUuid: string;
};

type JoinMembershipResponse = {};

export const joinMembership = async (
  payload: JoinMembershipPayload,
): Promise<JoinMembershipResponse> => {
  const response = await backendApi.post<
    any,
    AxiosResponse<JoinMembershipResponse>,
    JoinMembershipPayload
  >("v2/membership/join", payload);
  return response.data;
};
