import React from "react"
import "./brief.css";
import {ReactComponent as GeoSymbol} from "../../assets/symbols/geo.svg"
import {ReactComponent as ClockOutlineSymbol} from "../../assets/symbols/clock-outline.svg"
import {ReactComponent as PhoneSymbol} from "../../assets/symbols/phone.svg"
import ParamLink from "../../ImportedComponents/ParamLink/param_link";

function Brief() {
  return (
    <div className="brief">
      <h2 className="brief__heading">
        iSQUARE
      </h2>
      <div className="brief__actions">
        <ParamLink icon={GeoSymbol} url="#" hasMap>LB02, iSQUARE International Plaza, 63 Nathan Rd, Tsim Sha Tsui</ParamLink>
        <ParamLink icon={ClockOutlineSymbol} url="#" hasChevron><span className="text-success">Open</span> . Closes 9 pm</ParamLink>
        <ParamLink icon={PhoneSymbol} url="#">29479553</ParamLink>
      </div>
    </div>
  );
}

export default Brief;
