import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../shared/imports/_variables.css";
import "./auth.css";
import LogoImagePath from "./content/logo@3x.png";
import AppInput from "../../ImportedComponents/AppInput/app_input";
import AppButton from "../../ImportedComponents/AppButton/app_button";
import Label from "../../ImportedComponents/Label/label";
import Container from "../../Components/container/container";
import { getDeviceUuid } from "../../utils/getDeviceUuid";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useLocation, useNavigate } from "react-router-dom";
import { getPhoneOtp, getEmailOtp } from "../../services/api/getOtp";
import Capcha from "../../Components/capcha/capcha";
import openNotification from "../../Components/Antd/Notification/Notification";
import { authSlice } from "../../store/slices/auth";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
// import { Flex } from "@chakra-ui/react";
import axios from "axios";
import "react-international-phone/style.css";
import "../../shared/plugins-overrides/react-international-phone.css";
import backendApi from "../../services/api/backendApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Helper from "../../ImportedComponents/Helper/helper";

function Login() {
  const allowedCountries = ["in", "au", "hk"];
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const phoneValidation = usePhoneValidation(phoneNumber);

  const isLoginTypeNumber = useSelector(
    (state: RootState) => state.auth.isLoginTypeNumber,
  );
  console.log("Login Type:", isLoginTypeNumber);

  const { search } = useLocation();
  const navigate = useNavigate();
  const turnstileRef = useRef<TurnstileInstance>(null);
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["hk", "sg", "gb", "in", "au", "th", "fr", "de", "it"].includes(
      iso2,
    );
  });

  const handlePhoneLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      // Check if the inputEmail matches the emailRegex
      // if (!email) {
      //   openNotification(
      //     "warning",
      //     "Please enter a valid email address.",
      //     null,
      //   );
      //   return;
      // }

      const turnstileToken = turnstileRef.current?.getResponse();

      if (!turnstileToken) {
        openNotification(
          "warning",
          "Please complete the captcha challenge.",
          null,
        );
        return;
      }

      setIsLoading(true);

      try {
        console.log(phoneNumber);

        // const response = await backendApi.post(`${process.env.REACT_APP_API_URL}v3/auth/getPhoneOTP`, {
        //   phoneNumber: phoneNumber,
        //   deviceID: getDeviceUuid()
        // });

        const response = await getPhoneOtp({
          phoneNumber,
          deviceID: getDeviceUuid(),
          turnstileToken,
        });

        console.log(response);

        if (response.result) {
          dispatch(authSlice.actions.setphoneNumberForLogin(phoneNumber));
          openNotification(
            "success",
            `An OTP has been delivered to the phone number ${phoneNumber}`,
            null,
          );
          navigate({
            pathname: "/verify",
            search,
          });
        } else {
          throw new Error("Received unexpected response from getOTP endpoint");
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Unable to send OTP`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [email, navigate, dispatch, search, phoneNumber],
  );

  const handleEmailLogin = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      // Check if the inputEmail matches the emailRegex
      // if (!email) {
      //   openNotification(
      //     "warning",
      //     "Please enter a valid email address.",
      //     null,
      //   );
      //   return;
      // }

      const turnstileToken = turnstileRef.current?.getResponse();

      if (!turnstileToken) {
        openNotification(
          "warning",
          "Please complete the captcha challenge.",
          null,
        );
        return;
      }

      setIsLoading(true);

      try {
        console.log(email);

        const response = await getEmailOtp({
          email,
          deviceID: getDeviceUuid(),
          turnstileToken,
        });

        console.log(response);

        if (response.result) {
          dispatch(authSlice.actions.setEmailForLogin(email));
          openNotification(
            "success",
            `An OTP has been delivered to the phone number ${email}`,
            null,
          );
          navigate({
            pathname: "/verify",
            search,
          });
        } else {
          throw new Error("Received unexpected response from getOTP endpoint");
        }
      } catch (err) {
        console.error(err);
        openNotification("error", `Unable to send OTP`, null);
      } finally {
        setIsLoading(false);
      }
    },
    [email, navigate, dispatch, search, phoneNumber],
  );

  useEffect(() => {
    console.log(phoneNumber);
  }, [phoneNumber]);

  return (
    <div style={{ background: "white" }}>
      <div className="auth">
        <div className="auth__head">
          <Container className="auth__head-wrap" variant="smartphone-column">
            <img
              className="auth__logo"
              src={LogoImagePath}
              width="199"
              height="62"
              alt=""
            />
          </Container>
        </div>
        <Container className="auth__wrap" variant="smartphone-column">
          <form className="auth__form">
            <fieldset className="auth__fieldset" disabled={isLoading}>
              <div className="auth__main-heading">Sign-up/Sign-in</div>
              <div className="auth__main-note">
                {isLoginTypeNumber
                  ? "Enter your phone number to receive a one-time code to login."
                  : "Enter your email address to receive a one-time code to login."}
              </div>
              <div className="auth__panel">
                <div className="auth__field">
                  {isLoginTypeNumber ? (
                    <Label className="label">Mobile Number</Label>
                  ) : (
                    <Label className="label">Email</Label>
                  )}
                  {isLoginTypeNumber ? (
                    <PhoneInput
                      defaultCountry="hk" // Set the default country or leave it empty for auto-detection
                      value={phoneNumber}
                      onChange={(value) => {
                        setPhoneNumber(value);
                      }}
                      countries={countries}
                    />
                  ) : (
                    // <></>
                    <AppInput
                      tag="input"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  )}
                </div>
                <button
                  className="auth__swap-method"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      authSlice.actions.setIsLoginTypeNumber(
                        !isLoginTypeNumber,
                      ),
                    );
                  }}
                >
                  {isLoginTypeNumber
                    ? "Sign-up/Sign-in with Email"
                    : "Sign-up/Sign-in with Phone Number"}
                </button>
                <div className="auth__field">
                  <Capcha>
                    <Turnstile
                      siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY ?? ""}
                      options={{
                        appearance: "interaction-only",
                      }}
                      onError={() => console.log("captcha validation failed")}
                      onBeforeInteractive={() => {
                        setTurnstileRequireInteractive(true);
                      }}
                      ref={turnstileRef}
                    />
                  </Capcha>
                </div>
              </div>

              <div className="auth__submit">
                <AppButton
                  uniqueModifier="submit"
                  fullWidth
                  onClick={
                    isLoginTypeNumber ? handlePhoneLogin : handleEmailLogin
                  }
                >
                  Send Code
                </AppButton>
                {/* /temp */}
              </div>
            </fieldset>
          </form>
        </Container>
      </div>
    </div>
  );
}

export default Login;

// TODO: set login type using this
// dispatch(authSlice.actions.setIsLoginTypeNumber(true));
