import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type Program = {
  uuid: string;
  isSelected: boolean;

};

// Define a type for the slice state
interface ProgramState {
  programs: Program[];
}

// Define the initial state using that type
const initialState: ProgramState = {
  programs: [],
};

export const programSlice = createSlice({
  name: "program",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addProgram: (state, action: PayloadAction<Program>) => {
      state.programs.push(action.payload);
    },
    removeProgram: (state, action: PayloadAction<string>) => {
      state.programs = state.programs.filter((program) => program.uuid !== action.payload);
    },
  },
});

export default programSlice.reducer;
